import * as React from 'react'
import cn from 'classnames'
import { ColourVariant } from '../../../types/colour-variant'
import styles from './Notice.module.scss'

export type NoticeProps = {
  /**
   * Children for the notice, this can be text or custom React
   * @example <Notice>My custom notice content!</Notice>
   */
  children: React.ReactNode
  /**
   * The variant for the notice, by default this will be based off the base theme colors
   * @example <Notice variant="theme" />
   */
  variant: ColourVariant
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

/*
 * Typically a block element, may be full width. Sticks around until refresh, or may be dismissible.
 */
export const Notice: React.VFC<NoticeProps> = (props) => {
  const { children, variant, className, ...restProps } = props

  return (
    <div
      className={cn(styles.notice, styles[variant], className)}
      {...restProps}
    >
      {children}
    </div>
  )
}
